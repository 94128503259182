<div class="chat-head chat-head-chatbox row no-gutters">
    <div class="chatbox-title">
        <div class="chatbox-title--row">
            {[ if (!o._converse.singleton) { ]}
                <div class="chatbox-navback"><i class="fa fa-arrow-left"></i></div>
            {[ } ]}
            {[ if (o.type !== o._converse.HEADLINES_TYPE) { ]}
                <div class="chat-shortname">{{{o.short_name}}}</div>
            {[ } ]}
            <div class="chatbox-title__text" title="{{{o.jid}}}">
                {[ if (o.url) { ]} <a href="{{{o.url}}}" target="_blank" rel="noopener" class="user"> {[ } ]}
                    {{{ o.display_name }}}
                {[ if (o.url) { ]} </a> {[ } ]}
            </div>
        </div>
        <div class="chatbox-title__buttons row no-gutters">
            <a id="actionMenu" class="chatbox-btn action-menu-button fa fa-bars" title="{{{o.action_menu}}}"></a>
        </div>
        <div id="dropdownMenuList" class="dropwon-action-menu">
            <!-- CON-4984 :: changes :: hide set status away/chat temporarily -->
            <!-- <a class="dropdown-list-item toogle-availability-button" id="toggleAvailability">{{{o.info_availability}}}</a> -->
            <a class="dropdown-list-item save-chat-file-button" id="saveChatFile">{{{o.save_chat_file}}}</a>
            <a class="dropdown-list-item close-chatbox-button">{{{o.info_close}}}</a>
        </div>
    </div>
    <p class="chat-head__desc">{{{ o.status }}}</p>
    <div class="chat-head-separator"></div>
</div>
