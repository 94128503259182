<div style="display: grid;" id="message-container"
    class="message chat-msg {{{o.type}}} {{{o.extra_classes}}} {[ if (o.is_me_message) { ]} chat-msg--action {[ } ]}"
    data-isodate="{{{o.time}}}" data-msgid="{{{o.msgid}}}" data-from="{{{o.from}}}"
    data-encrypted="{{{o.is_encrypted}}}">
    {[ if (o.type !== 'headline' && !o.is_me_message) { ]}
    <!-- <canvas class="avatar chat-msg__avatar" height="36" width="36"></canvas> -->
    {[ } ]}
    {[ if (!o.is_chat_form) { ]}
    <div
        class="chat-msg__content chat-msg__content--{{{o.sender}}} {{{o.is_me_message ? 'chat-msg__content--action' : ''}}}">
        {[ if (o.is_own_message) { ]}
        <span class="chat-msg__heading" style="display: block; text-align: right;">
            {[ if (o.is_me_message) { ]}<time timestamp="{{{o.isodate}}}"
                class="chat-msg__time">{{{o.pretty_time}}}</time>{[ } ]}
            {[ if (!o.is_me_message) { ]}
            {[o.roles.forEach(function (role) { ]} <span class="badge badge-secondary">{{{role}}}</span> {[ }); ]}
            {[ } ]}
            {[ if (o.is_encrypted) { ]}<span class="fa fa-lock"></span>{[ } ]}
        </span>
        {[ } else { ]}
        <span class="chat-msg__heading">
            {[ if (o.is_me_message) { ]}<time timestamp="{{{o.isodate}}}"
                class="chat-msg__time">{{{o.pretty_time}}}</time>{[ } ]}
            <span class="chat-sender-label">{{{o.username}}}</span>
            {[ if (!o.is_me_message) { ]}
            {[o.roles.forEach(function (role) { ]} <span class="badge badge-secondary">{{{role}}}</span> {[ }); ]}
            {[ } ]}
            {[ if (o.is_encrypted) { ]}<span class="fa fa-lock"></span>{[ } ]}
        </span>
        {[ } ]}
        <div
            class="{{{o.is_own_message ? 'chat-msg__body_own_message' : 'chat-msg__body' }}} chat-msg__body--{{{o.type}}} {{{o.received ? 'chat-msg__body--received' : '' }}} {{{o.is_delayed ? 'chat-msg__body--delayed' : '' }}}">
            <div class="chat-msg__message">
                {[ if (o.is_retracted) { ]}
                <div>{{{o.retraction_text}}}</div>
                {[ if (o.moderation_reason) { ]}<q class="chat-msg--retracted__reason">{{{o.moderation_reason}}}</q>{[ }
                ]}
                {[ } else { ]}
                {[ if (o.is_spoiler) { ]}
                <div class="chat-msg__spoiler-hint">
                    <span class="spoiler-hint">{{{o.spoiler_hint}}}</span>
                    <a class="badge badge-info spoiler-toggle" data-toggle-state="closed" href="#"><i
                            class="fa fa-eye"></i>{{{o.label_show}}}</a>
                </div>
                {[ } ]}

                {[ if (o.subject) { ]}
                <div class="chat-msg__subject">{{{ o.subject }}}</div>
                {[ } ]}
                {[ if (o.is_own_message) { ]}
                <div style="text-align: right;" class="chat-msg__text chat-message-reciever
                            {[ if (o.is_only_emojis) { ]} chat-msg__text--larger{[ } ]}
                            {[ if (o.is_spoiler) { ]} spoiler collapsed{[ } ]}">
                    <!-- message gets added here via renderMessage -->
                </div>
                <div class="chat-msg__media"></div>
                <time timestamp="{{{o.isodate}}}" class="chat-msg__time"
                    style="text-align: right;">{{{o.pretty_time}}}</time>
                {[ } else { ]}
                <div style="margin-left: 23px;">
                    <div class="chat-msg__text chat-message-sender
                                                {[ if (o.is_only_emojis) { ]} chat-msg__text--larger{[ } ]}
                                                {[ if (o.is_spoiler) { ]} spoiler collapsed{[ } ]}">
                        <!-- message gets added here via renderMessage -->
                    </div>
                    <div class="chat-msg__media"></div>
                    <time timestamp="{{{o.isodate}}}" class="chat-msg__time">{{{o.pretty_time}}}</time>
                </div>
                {[ } ]}
                {[ } ]}
            </div>
            {[ if (o.received && !o.is_me_message && !o.is_groupchat_message) { ]}
            <span style="margin-left: 5px;" class="fa fa-check chat-msg__receipt"></span>
            {[ } ]}
            {[ if (o.edited) { ]} <i title="{{{o.__('This message has been edited')}}}"
                class="fa fa-edit chat-msg__edit-modal"></i> {[ } ]}
            <div class="chat-msg__actions">
                <!-- CON-4320 :: Changes :: hiding edit and retrace button -->
                {[ if (o.editable) { ]}
                <!-- <button class="chat-msg__action chat-msg__action-edit fa fa-pencil-alt" title="{{{o.__('Edit this message')}}}"></button> -->
                {[ } ]}
                {[ if (o.retractable) { ]}
                <!-- <button class="chat-msg__action chat-msg__action-retract fa fa-trash-alt" title="{{{o.__('Retract this message')}}}"></button> -->
                {[ } ]}
            </div>
        </div>
    </div>
    {[ } ]}
</div>