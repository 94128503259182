<div class="chatroom-container-form-generic chat-generic-form">
    <div class="chatroom-generic-form">
        <div class="form-group">
            <legend>{{{o.title}}}</legend>
            {[ if (o.title !== o.instructions) { ]}
                <p class="form-help">{{{o.instructions}}}</p>
            {[ } ]}
            <!-- Fields are generated internally, with xForm2webForm -->
            {[ o.fields.forEach(function (field) { ]} {{ field }} {[ }) ]}
        </div>
    </div>
</div>
