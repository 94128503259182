<div class="flyout box-flyout">
    <div class="chat-body">
        <div id="chat-view-container" class="chat-content {[ if (o.show_send_button) { ]}chat-content-sendbutton{[ } ]}" aria-live="polite"></div>
        <div class="bottom-panel">
            <div class="emoji-picker__container dropup"></div>
            <div class="message-form-container">
        </div>
    </div>
</div>
<div class="chat-bottom-separator"></div>
