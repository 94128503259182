<div class="new-msgs-indicator hidden">▼ {{{ o.unread_msgs }}} ▼</div>
<form class="setNicknameButtonForm hidden">
    <input type="submit" class="btn btn-primary" name="join" value="Join"/>
</form>
<form class="sendXMPPMessage">
    {[ if (o.show_toolbar || o.show_send_button) { ]}
    <div class="chat-toolbar--container">
    {[ if (o.show_toolbar) { ]}
        <ul class="chat-toolbar no-text-select"></ul>
    {[ } ]}
    </div>
    {[ } ]}
    <input type="text" placeholder="{{o.label_spoiler_hint}}" value="{{ o.hint_value }}"
           class="{[ if (!o.composing_spoiler) { ]} hidden {[ } ]} spoiler-hint"/>

    <div style="display: flex;">
        <ul class="suggestion-box__results suggestion-box__results--above" hidden=""></ul>
        <textarea
            type="text"
            class="chat-textarea suggestion-box__input chat-textarea-length
                {[ if (o.show_send_button) { ]} chat-textarea-send-button {[ } ]}
                {[ if (o.composing_spoiler) { ]} spoiler {[ } ]}"
            placeholder="{{{o.label_message}}}">{{ o.message_value }}</textarea>
            <div class="chat-button-group">
                <div class="toggle-toolbar-menu toggle-smiley__container">
                    <a class="toggle-smiley far fa-smile" title="{{{o.tooltip_insert_smiley}}}" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" style="margin-bottom: 10px;"></a>
                </div>
                {[ if (o.show_send_button) { ]}
                <a type="submit" class="send-button fa fa-paper-plane" title="{{{ o.__('Send the message') }}}"></a>
                {[ } ]}
            </div>
        <span class="suggestion-box__additions visually-hidden" role="status" aria-live="assertive" aria-relevant="additions"></span>
    </div>
</form>
